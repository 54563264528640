<template>
  <div id="typeWorkday" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">TIPOS DE JORNADA</v-row>
      <v-row no-gutters justify="end" class="mr-12">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addWorkdayDos" id="nuevajornada">
              <button class="breakSearchE botonAmarillo">
                Alta de jornada
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="workdays"
            :search="search"
             class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Tipos de Jornada por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias'}"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.claveCFDI }}</td>
                  <td class="text-sm-center">{{ item.nombreJornada }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Editar item-->
    <v-dialog v-model="edit" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de datos</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="form-group mt-9 mb-5">
              <label for="workday" class="ml-2">Id tipo de Jornada</label>
              <input
                type="text"
                name="workday"
                id="workday"
                placeholder="Obligatorio"
                class="ml-5 inputs"
                autocomplete="false"
                v-model="idWorkday"
                required
                maxlength="2"
                @keypress="isNumber($event)"
              />
            </div>
          </v-row>
          <v-row>
            <div class="form-group">
              <label for="description" class="ml-2 mr-3">Descripción</label>
              <input
                type="text"
                name="description"
                id="description"
                placeholder="Obligatorio"
                class="ml-12 inputs"
                autocomplete="false"
                v-model="description"
                required
                maxlength="20"
                @keypress="isLetter($event)"
              />
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Debe especificar el id y descripción del tipo de
                jornada</label
              >
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Tipo de jornada</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "claveCFDI",
        },
        {
          text: "Descripción tipo de Jornada",
          align: "center",
          value: "nombreJornada",
        },
        {
          text: "Editar  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      workdays: [],
      respuesta: "",
      advice: false,
      idWorkday: "",
      id: "",
      confirmation: false,
      description: "",
      edit: false,
      vacio: false,
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    editItem(item) {
      console.log("Ingreso a editar" + item.id + item.descripcion);
      this.id = item.id;
      this.idWorkday = item.claveCFDI;
      this.description = item.nombreJornada;
      this.edit = true;
    },
    validacion: function () {
      this.errors = [];
      if (
        this.idWorkday == "" ||
        this.idWorkday == " " ||
        this.idWorkday == 0
      ) {
        this.errors.push("Se debe especificar el id del tipo de jornada.");
      }
      if (this.description == "" || this.description == " ") {
        this.errors.push(
          "Se debe especificar la descripción del tipo de jornada."
        );
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      //console.log("El id para la api es" + this.idWorkday);
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/jornadas/" + this.id,
          {
            id: this.id,
            claveCfdi: this.idWorkday,
            nombreJornada: this.description,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.id = "";
          this.idWorkday = "";
          this.description = "";
          this.respuesta = "El elemento fue actualizado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.idWorkday = "";
            this.description = "";
            this.respuesta = "El elemento no fue actualizado";
            this.confirmation = true;
          }
        });
    },
    /*
      temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
      guarda solo el id del dato
      */
    temporal(item) {
      console.log("El id es: " + item.id);
      this.id = item.id;
      this.idWorkday = item.claveCFDI;
      this.respuesta =
        "¿ Seguro que desea eliminar el elemento " + item.nombreJornada + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/jornadas/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.show = false;
          this.confirmation = true;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idWorkday = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      console.log("Ingreso a detalle" + item.id);
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/jornadas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.workdays = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    isLetter: function (evt) {
      var regex = new RegExp("^[a-zA-Z ]+$"); // Letras y espacio
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aux() {
      this.edit = false;
      this.id = "";
      this.idWorkday = "";
      this.description = "";
      this.vacio = false;
    },
  },
  created() {
    this.listar();
  },
};
</script>